<style>

</style>

<template>
    <div id="cancellation-report" style="padding: 0 1.5rem">
        <div class="pb-1 mb-0 mb-lg-1">
            <SearchForm @search="search" :reportName="reportName" :isProcessing="isProcessing"></SearchForm>
            <ReportTable
                :cancelledOppsAff="cancelOpportunities"
                :totalRows="totalRows"
                :reportName="reportName"
                :isProcessing="isProcessing"
                @search="search"
            ></ReportTable>
    </div>

    </div>
</template>

<script>
import Global from "@/stores/GlobalVariables.js";
import mixin from "@/components/Mixin";
import SearchForm from "@/components/SearchForm";
import ReportTable from "@/components/ReportTable";
import axios from "axios";

export default {
    mixins: [mixin],
    name: "CancellationAffiliateReport",
    components: { SearchForm, ReportTable },
    data: function () {
        return {
            Global,
            cancelOpportunities: [],
            totalRows: 0,
            cancelOpportunity: null,
            isProcessing: false,
            reportName: "cancellationAffiliateReport"
        };
    },
    created(){
        if(!this.isNullOrEmpty(this.$route.query.start) && !this.isNullOrEmpty(this.$route.query.end) && !this.isNullOrEmpty(this.$route.query.dateType)){
            Global.searchConditions[this.reportName].dateFrom = this.$route.query.start;
            Global.searchConditions[this.reportName].dateTo = this.$route.query.end;
            Global.searchConditions[this.reportName].policyStatus = this.$route.query.stage ? this.$route.query.stage : "";
            Global.searchConditions[this.reportName].status = this.$route.query.status ? this.$route.query.status : "";
            Global.searchConditions[this.reportName].dateType = this.$route.query.dateType;
        }else{
            Global.searchConditions[this.reportName].dateFrom = "";
            Global.searchConditions[this.reportName].dateTo = "";
            Global.searchConditions[this.reportName].oppNumber = "";
            Global.searchConditions[this.reportName].dateType = "cancelleddate";
            Global.searchConditions[this.reportName].sortKey = "cancelleddate";
            Global.searchConditions[this.reportName].sortDirection = "desc";
            Global.searchConditions[this.reportName].currentPage = 1;
            Global.searchConditions[this.reportName].rowsPerPage = 20;
            Global.searchConditions[this.reportName].isAdvanceSearch = false;
        }
        this.eventTracking('mainMenu', 'Menu_CancellationReport', 'Portal');
    }, 
    mounted(){
        this.hide2menu();
        this.search()
    },
    methods: {
        async search() {
            this.isProcessing = true;
            try {
                var response = await axios.get(
                Global.apiUrl + "/cancellationReport",
                    {
                        params: Global.searchConditions[this.reportName],
                    }
                );
                this.cancelOpportunities = response.data.cancellation;
                this.totalRows = parseInt(response.data.count);
                this.isProcessing = false;
            } catch (error) {
                if (error?.response?.status === 401) {
                this.isProcessing = false;
                this.$router.go();
                }
                console.error(error);
            }
        }
    }
}
</script>

